<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row class="text-no-wrap">
      <v-col cols="12 d-flex flex-row-reverse">
        <v-btn
          color="teal lighten-1 float-right"
          depressed
          dark
          @click="openSecondColumn"
        >
          <v-icon>mdi-credit-card-plus</v-icon>
          <span class="white--text ml-1">New Details</span>
        </v-btn>
        <download-excel
            :data="getClientAccountingData"
            :name="fileName"
            :fields="accountingFields"
          >
            <v-btn class="ml-5 mr-5" color="teal lighten-1" depressed dark>
              <span class="white--text ml-1">Download Excel</span>
            </v-btn>
          </download-excel>
      </v-col>
      <v-col :cols="layoutColumn">
        <v-card flat outlined>
          <v-data-table
            :headers="headers"
            :items="accountings"
            :search="search"
            class="elevation-0"
            outlined
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Accounting</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <!-- <template v-slot:item.vatStatus="{ item }">{{
              vatStatus(item)
            }}</template>
            <template v-slot:item.vatReturnPeriod="{ item }">{{
              vatReturnPeriod(item)
            }}</template> -->
            <!-- <template v-slot:item.fsPeriod="{ item }">{{
              dateFormat({ from: item.fsStatementFrom, to: item.fsStatementTo })
            }}</template>
            <template v-slot:item.itrPeriod="{ item }">{{
              dateFormat({
                from: item.incomeTaxReturnFrom,
                to: item.incomeTaxReturnTo,
              })
            }}</template> -->
            <!-- <template v-slot:item.itu="{ item }">{{ itu(item) }}</template>-->
            <template v-slot:item.actions="{ item }"> 
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="downloadItem(item)">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </template>
                <span>Download document</span>
              </v-tooltip> -->
              <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" @click="editItem(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit client details</span>
                      </v-tooltip>
                      
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete client from accounting</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      
      <AccountingDetails
        :is-second-column="isSecondColumn"
        :record-data="accountings"
        @closeSecondColumn="closeSecondColumn"
        @refreshAccounting="getAllAccountings"
      />
      
    </v-row>
  
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import AccountingDetails from '@components/Accounting/AccountingDetails.vue'
import api from '@src/api/index.js'
import format from 'date-fns/format'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import { validateError } from '@utils/validateFields'
export default {
  name: 'Accounting',
  components: {
    AccountingDetails,
    Layout,
  },
  props: {
    clientId: {
      type: String,
      default: ''
    },
  }, 
  data() {
    return {
      search: '',
      isSecondColumn: false,
      isOpenTabForm: false,
      isOpenEditTabForm: false,
      editRecordData: {},
      tabName: 'Accounting',
      editClientid: 0,
      layoutColumn: 12,
      getActiveTab:1,
      setActiveTab:0,  // Sets which tab is the active one from start | integer based
      accountings: [],
      accountingsData: [],
      vatReturnsData: [],
      vatRecapsData: [],
      taxData: [],
      employeeData: [],
      loading: false,
      employee: '',
       headers: [
        { text: 'Client', align: 'left', value: 'name' },
        { text: 'VAT Status', value: 'vatStatus', align: 'center' },
        { text: 'VAT Return Period', value: 'vatReturnPeriod', align: 'center' },
        { text: 'PE Number', value: 'peNumber', align: 'center' },
        { text: 'ETC Number', value: 'etcNumber', align: 'center' },
        { text: 'Income Tax reg No', value: 'incomeTaxRegNo', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
       getAllAccData: [],
      getClientAccountingData: [],
      fileName:"accounting-data",
      accountingFields:{
	      "Client Name":"name",
        "Finanicial Year":"data.finanicialYear",
	      "Accounting Fees":"data.accountingFees",
        "Vat Compliance Fees":"data.vatComplianceFees",
	      "Tax Compliance Fees":"data.taxComplianceFees",
        "Concluded":"data.concluded",
	      "Audit":"data.audit",
        "Audit Fees":"data.auditFees",
	      "Paid & Unpaid":"data.paidUnpaid"
  },
    }
  },
  computed: {},

  created() {
    this.getAllAccountings();
    this.getAllAccountData();
  },
  methods: {
    icon(val) {
      return this.icons[val]
    },
    forceFileDownload(blob, fileName) {
      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob)
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    async downloadItem({ documentId }) {
      this.loading = true
      try {
        const { data, fileName } = await api.getFileDownload(documentId)
        this.forceFileDownload(data, fileName)
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    dateFormat({ from, to }) {
      if (from && to) {
        return `  
        ${format(new Date(from), 'dd yyyy')} - ${format(
          new Date(to),
          'dd yyyy'
        )}`
      }
      return 'n/a'
    },
    vatReturn({ vatReturnPaid }) {
      return vatReturnPaid ? 'Paid' : 'No'
    },
    itu({ ituRegistration }) {
      return ituRegistration || 'Not applicable'
    },
    openSecondColumn() {
      this.isSecondColumn = true
      this.layoutColumn = 6
    },
    async getAllAccountings() {
      this.loading = true
      try {
        const accountings = await api.getAllAccountings()
        this.accountings = accountings;
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
  
    async deleteAccountingData (accountingId) {
      this.loading = true
      try {
        const data = await api.deleteAccounting(accountingId)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.getAllAccountings()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
         this.getAllClientAccountingData();
      }
    },

    closeSecondColumn() {
      this.isSecondColumn = false
      if (!this.isOpenTabForm && !this.isOpenEditTabForm) {
        this.layoutColumn = 12
      }
    },
   deleteItem({ accountingId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete accounting',
        message: 'Are you sure to delete this accounting? The operation is not reversible',
        confirmAction: () => this.deleteAccountingData(accountingId),
      })
    },
    async editItem(item) {
      try {
        this.$router.push({ name: 'edit-accounting', params: { item } })
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async getAllAccountData() {
      this.loading = true
      try {
        const accountingData = await api.getAllAccountingData()
        this.getAllAccData = accountingData.accounting_data;
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
        this.getAllClientAccountingData();
      }
    },
     async getAllClientAccountingData() {
      this.getClientAccountingData = [];
      [...this.accountings].forEach(element => {
        [...this.getAllAccData].forEach(ele => {
          if(element.clientId === ele.clientId) {
            this.getClientAccountingData.push({"name":element.name,"data":ele})
          }
        }); 
      });
    }
  },
}
</script>
<style>
</style>