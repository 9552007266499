<template>
  <v-scroll-x-reverse-transition>
    <v-col v-show="isSecondColumn" cols="6">
      <v-form ref="accountingInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>

            <h2>Client Name <v-tooltip bottom max-width="250" color="rgba(0,0,0,.8)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="blue-grey lighten-3"
                    v-bind="attrs"
                    class="ml-5"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span
                >You can add accounting details only for existing clients. Type the client name and then select from the suggestions</span
>
              </v-tooltip></h2>
            <v-row class="px-10" align="center" justify="center">
              <v-col cols="12">
                <v-autocomplete
                  v-model="clientId"
                  outlined
                  :items="clients"
                  :search-input.sync="search"
                  dense
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.clientId, 'Client Name')"
                  item-text="name"
                  item-value="clientId"
                  label="Start typing the client name..."
                  return-object
                  @change="autocompleteChange"
                  @blur="$v.clientId.$touch"
                />
              </v-col>
            </v-row>
            <!-- <p>VAT Return</p>
            <v-row class="px-10">
              <v-col cols="4">
                <v-checkbox
                  v-model="vatReturnPaid"
                  label="Vat return paid?"
                />
              </v-col>
              <v-col v-if="vatReturnPaid" cols="4">
                <v-menu
                  ref="menuVatFrom"
                  v-model="vatMenuFrom"
                  :close-on-content-click="false"
                  :return-value.sync="vatMenuFrom"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vatReturnFrom"
                      outlined
                      dense
                      label="Actual"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="
                        requiredError($v.vatReturnFrom, 'VAT Return From date')
                      "
                      v-on="on"
                      @change="$v.vatReturnFrom.$touch"
                      @blur="$v.vatReturnFrom.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vatReturnFrom"
                    no-title
                    scrollable
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      @click="vatMenuFrom = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuVatFrom.save(vatReturnFrom)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col v-if="vatReturnPaid" cols="4">
                <v-menu
                  ref="menuVatTo"
                  v-model="vatMenuTo"
                  :close-on-content-click="false"
                  :return-value.sync="vatMenuTo"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="vatReturnTo"
                      color="red darken-4"
                      outlined
                      dense
                      label="Next"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="
                        requiredAndDateErrors(
                          $v.vatReturnTo,
                          'VAT Return To date',
                        )
                      "
                      v-on="on"
                      @change="$v.vatReturnTo.$touch"
                      @blur="$v.vatReturnTo.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="vatReturnTo"
                    no-title
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn text color="red darken-4" @click="vatMenuTo = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuVatTo.save(vatReturnTo)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <p v-if="corporate">Registered with ITU?</p>
            <v-row v-if="corporate" class="px-10">
              <v-col cols="12">
                <v-radio-group
                  v-model="itu"
                  mandatory
                  row
                  :error-messages="requiredError($v.vatReturnTo, 'ITU')"
                  @change="$v.itu.$touch"
                  @blur="$v.itu.$touch"
                >
                  <v-radio
                    color="red darken-4"
                    label="Yes"
                    value="yes"
                  ></v-radio>
                  <v-radio color="red darken-4" label="No" value="no"></v-radio>
                  <v-radio
                    color="red darken-4"
                    label="Not applicable"
                    value="not applicable"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <p v-if="corporate">Financial Statement</p>
            <v-row v-if="corporate" class="px-10">
              <v-col cols="6">
                <v-menu
                  ref="menuFsFrom"
                  v-model="fsMenuFrom"
                  :close-on-content-click="false"
                  :return-value.sync="fsMenuFrom"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fsStatementFrom"
                      color="red darken-4"
                      outlined
                      dense
                      label="Actual"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="
                        requiredError($v.fsStatementFrom, 'FS Statement From')
                      "
                      v-on="on"
                      @change="$v.fsStatementFrom.$touch"
                      @blur="$v.fsStatementFrom.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fsStatementFrom"
                    no-title
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn text color="red darken-4" @click="fsMenuFrom = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuFsFrom.save(fsStatementFrom)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="menuFsTo"
                  v-model="fsMenuTo"
                  :close-on-content-click="false"
                  :return-value.sync="fsMenuTo"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="fsStatementTo"
                      color="red darken-4"
                      outlined
                      dense
                      label="Next"
                      :error-messages="
                        requiredAndDateErrors(
                          $v.fsStatementTo,
                          'FS Statement To',
                        )
                      "
                      prepend-icon="mdi-calendar"
                      readonly
                      @change="$v.fsStatementTo.$touch"
                      @blur="$v.fsStatementTo.$touch"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fsStatementTo"
                    no-title
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn text color="red darken-4" @click="fsMenuTo = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuFsTo.save(fsStatementTo)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="11">
                    <v-file-input
                      v-model="fsStatementFile"
                      outlined
                      dense
                      color="red darken-4"
                      accept="image/png, image/jpeg, pdf"
                      show-size
                      counter
                      :error-messages="fileErrors($v.fsStatementFile)"
                      label="Upload Financial statement file"
                      @change="$v.fsStatementFile.$touch"
                      @blur="$v.fsStatementFile.$touch"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="fsStatementNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="Document Number"
                />
              </v-col>
            </v-row>
            <p v-if="individual">Personal Income Tax Return</p>
            <v-row v-if="individual" class="px-10">
              <v-col cols="4">
                <v-menu
                  ref="menuItrFrom"
                  v-model="itrMenuFrom"
                  :close-on-content-click="false"
                  :return-value.sync="itrMenuFrom"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="incomeTaxReturnFrom"
                      color="red darken-4"
                      outlined
                      dense
                      :error-messages="
                        requiredError(
                          $v.incomeTaxReturnFrom,
                          'Income Tax Return From',
                        )
                      "
                      label="Actual"
                      prepend-icon="mdi-calendar"
                      readonly
                      @change="$v.incomeTaxReturnFrom.$touch"
                      @blur="$v.incomeTaxReturnFrom.$touch"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="incomeTaxReturnFrom"
                    no-title
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn
                      text
                      color="red darken-4"
                      @click="itrMenuFrom = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuItrFrom.save(incomeTaxReturnFrom)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu
                  ref="menuItrTo"
                  v-model="itrMenuTo"
                  :close-on-content-click="false"
                  :return-value.sync="itrMenuTo"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="incomeTaxReturnTo"
                      color="red darken-4"
                      outlined
                      :error-messages="
                        requiredAndDateErrors(
                          $v.incomeTaxReturnTo,
                          'Income Tax Return To',
                        )
                      "
                      dense
                      label="Next"
                      prepend-icon="mdi-calendar"
                      readonly
                      @change="$v.incomeTaxReturnTo.$touch"
                      @blur="$v.incomeTaxReturnTo.$touch"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="incomeTaxReturnTo"
                    no-title
                    scrollable
                    color="red darken-4"
                  >
                    <div class="flex-grow-1"></div>
                    <v-btn text color="red darken-4" @click="itrMenuTo = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      text
                      color="teal lighten-1"
                      @click="$refs.menuItrTo.save(incomeTaxReturnTo)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-row no-gutters>
                  <v-col cols="11" class="pa-0">
                    <v-file-input
                      v-model="incomeTaxReturnFile"
                      outlined
                      dense
                      color="red darken-4"
                      accept="image/png, image/jpeg, pdf"
                      show-size
                      counter
                      :error-messages="fileErrors($v.incomeTaxReturnFile)"
                      label="Upload Income Tax Return File"
                      @change="$v.incomeTaxReturnFile.$touch"
                      @blur="$v.incomeTaxReturnFile.$touch"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="incomeTaxReturnNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="Document Number"
                />
              </v-col>
            </v-row> -->
          <v-row class="px-10" align="center" justify="center">
              <v-col cols="6">
                <v-select
                  v-model="vatReturnPeriodSelect"
                  :items="vatReturnPeriodOptions"
                  item-text="label"
                  item-value="value"
                  outlined
                  return-object
                  single-line
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="vatStatusSelect"
                  :items="vatStatusOptions"
                  item-text="label"
                  item-value="value"
                  outlined
                  return-object
                  single-line
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="12">
                <v-row>
                  <v-col cols="3">     
               PE Number:</v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="peNumber"
                      color="red darken-4"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="12">
                <v-row>
                  <v-col cols="3">     
               ETC Number:</v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="etcNumber"
                      color="red darken-4"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="12">
                <v-row>
                     <v-col cols="3">     
               Income Tax reg No:</v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="incomeTaxRegNo"
                      color="red darken-4"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="2">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import {
  // isSameOrAfter,
  validateError,
  // validExtension,
} from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents, ClientTypes } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'AccountingDetails',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      clientId: { required },
      //   vatReturnFrom: {
      //     required: requiredIf(() => this.vatReturnPaid),
      //   },
      //   vatReturnTo: {
      //     required: requiredIf(() => this.vatReturnPaid),
      //     ...(this.vatReturnPaid && {
      //       isSameOrAfter: isSameOrAfter(this.vatReturnFrom),
      //     }),
      //   },
      //   itu: {
      //     required: requiredIf(() => this.corporate),
      //   },
      //   fsStatementFrom: {
      //     required: requiredIf(() => this.corporate),
      //   },
      //   fsStatementTo: {
      //     required: requiredIf(() => this.corporate),
      //     ...(this.corporate && {
      //       isSameOrAfter: isSameOrAfter(this.fsStatementFrom),
      //     }),
      //   },
      //   fsStatementFile: {
      //     required: requiredIf(() => this.corporate),
      //     validExtension,
      //   },
      //   incomeTaxReturnFrom: {
      //     required: requiredIf(() => this.individual),
      //   },
      //   incomeTaxReturnTo: {
      //     required: requiredIf(() => this.individual),
      //     ...(this.individual && {
      //       isSameOrAfter: isSameOrAfter(this.incomeTaxReturnFrom),
      //     }),
      //   },
      //   incomeTaxReturnFile: {
      //     required: requiredIf(() => this.individual),
      //     validExtension,
      //   },
    }
  },
  props: {
    isSecondColumn: {
      type: Boolean,
      default: false,
    },
     recordData: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      incomeTaxReturnFrom: '',
      incomeTaxReturnTo: '',
      vatReturnPaid: false,
      itu: '',
      clientId: '',
      name: '',
      clientLoading: false,
      clients: [],
      loading: false,
      search: '',
      itrMenuFrom: '',
      clientType: '',
      itrMenuTo: '',
      fsMenuFrom: '',
      fsMenuTo: '',
      fsStatementFrom: '',
      fsStatementTo: '',
      fsStatementFile: null,
      fsStatementNumber: '',
      incomeTaxReturnFile: null,
      incomeTaxReturnNumber: '',
      vatReturnFrom: '',
      vatReturnTo: '',
      vatMenuFrom: '',
      vatMenuTo: '',
      vatReturnPeriod: '',
      vatStatus:'',
      vatReturnPeriodSelect: { label: 'Monthly', value: 'monthly' },
      vatReturnPeriodOptions: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Yearly', value: 'yearly' },
      ],
      vatStatusSelect: { label: 'Registered Articled 10', value: 'Registered Articled 10' },
      vatStatusOptions: [
        { label: 'Registered Articled 10', value: 'Registered Articled 10' },
        { label: 'Registered Articled 11', value: 'Registered Articled 11' },
        { label: 'De-Registered', value: 'de-registered' }
      ],
      peNumber:'',
      etcNumber:'',
      incomeTaxRegNo:''
    }
  },
  computed: {
    individual() {
      return this.clientType === ClientTypes.INDIVIDUAL
    },
    corporate() {
      return this.clientType === ClientTypes.CORPORATE
    },
  },
  created() {
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
  },
  methods: {
    autocompleteChange({ clientId, name, clientType }) {
      this.$v.clientId.$touch()

      // TODO: avoid here to call BE another time
      this.clientId = clientId
      this.clientType = clientType
      this.name = name
    },

    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Create Accounting',
          message: 'Are you sure to create accounting?',
          confirmAction: () => this.saveAccounting(),
        })
      }
    },
    fileErrors(file) {
      return validateError(
        file,
        ['required', 'validExtension'],
        ['File is required', 'Extension wrong, accepted: png, jpg, pdf']
      )
    },
    saveAccounting() {
      const payload = {
        clientId: this.clientId,
        // vatReturnPaid: !!this.vatReturnPaid,
        vatReturnPeriod: this.vatReturnPeriodSelect.value,
        vatStatus: this.vatStatusSelect.value,
        peNumber: this.peNumber,
        etcNumber: this.etcNumber,
        incomeTaxRegNo: this.incomeTaxRegNo
      }
      const formData = new FormData()
      formData.append('clientId', this.clientId)
      formData.append('clientType', this.clientType)
      formData.append('name', this.name)

      // if (this.vatReturnPaid) {
      //   payload.vatReturnFrom = this.vatReturnFrom
      //   payload.vatReturnTo = this.vatReturnTo
      // }

      // if (this.clientType === ClientTypes.INDIVIDUAL) {
      //   payload.incomeTaxReturnFrom = this.incomeTaxReturnFrom
      //   payload.incomeTaxReturnTo = this.incomeTaxReturnTo
      //   payload.incomeTaxReturnNumber = this.incomeTaxReturnNumber
      //   formData.append('incomeTaxReturn', this.incomeTaxReturnFile)
      // }
      // if (this.clientType === ClientTypes.CORPORATE) {
      //   payload.itu = this.itu
      //   payload.fsStatementFrom = this.fsStatementFrom
      //   payload.fsStatementTo = this.fsStatementTo
      //   payload.financialStatementNumber = this.fsStatementNumber
      //   formData.append('financialStatement', this.fsStatementFile)
      // }

      formData.append('payload', JSON.stringify(payload))
      this.saveAccountingDetails(formData)
    },
    async saveAccountingDetails(formData) {
      this.loading = true
      try {
        await api.createAccounting({ formData, clientType: this.clientType })
        this.loading = false
        setTimeout(() => this.close(), 0)
        this.$emit('refreshAccounting')
        this.$refs.accountingInfoForm.reset()
        this.vatReturnPeriodSelect = { label: 'Monthly', value: 'monthly' }
        this.vatStatusSelect = { label: 'Registered Articled 10', value: 'Registered Articled 10' }
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.accountingInfoForm.reset()
      this.vatReturnPeriodSelect = { label: 'Monthly', value: 'monthly' }
      this.vatStatusSelect = { label: 'Registered Articled 10', value: 'Registered Articled 10' }
      this.$emit('closeSecondColumn')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients.filter(ar => !this.recordData.find(rm => (rm.clientId === ar.clientId) ))
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    requiredAndDateErrors(field, fieldName) {
      return validateError(
        field,
        ['required', 'isSameOrAfter'],
        `${fieldName} is required and should be after the previous date`
      )
    },
  },
}
</script>

<style></style>
